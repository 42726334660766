import { calculateTimeRemaining, useBlockHeightQuery, useCurrentAuctionQuery } from "@/queries/auction";
import { useAccountStore } from "@/stores/accountStore";
import { motion } from 'framer-motion';
import { Fragment, useEffect, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

interface CountdownProps {
    className?: string;
    showLabel?: boolean;
}

export const Countdown = ({ className, showLabel }: CountdownProps) => {
    const network = useAccountStore(state => state.network);
    const { data: blockHeight, error: blockHeightError } = useBlockHeightQuery(network);
    const { data: currentAuction, error: currentAuctionError } = useCurrentAuctionQuery();
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    const endBlock = currentAuction ? currentAuction.end_block : null;
    const endBlockTime = currentAuction ? currentAuction.end_block_time : null;
    const blocksRemaining = endBlock && blockHeight ? endBlock - blockHeight : null;
    const isNearEnd = blocksRemaining && blocksRemaining <= 5;

    useEffect(() => {
        if (!endBlockTime) { return }
        const updateTimeLeft = () => setTimeLeft(calculateTimeRemaining(endBlockTime));
        updateTimeLeft();
        const intervalId = setInterval(updateTimeLeft, 1000);
        return () => clearInterval(intervalId);

    }, [endBlockTime]);

    return (
        <div>
            {blockHeightError || currentAuctionError ? (
                <div>
                    {blockHeightError && <p>Error: {blockHeightError.message}</p>}
                    {currentAuctionError && <p>Error: {currentAuctionError.message}</p>}
                </div>
            ) :
                <Fragment>
                    {isNearEnd
                        ? <div className="text-destructive text-xl animate-pulse">Auction ending soon!</div>
                        : <TimeUntil seconds={timeLeft} className={className} showLabel={showLabel} />
                    }
                </Fragment>
            }
        </div>
    );
};


const TimeUntil = ({ seconds, className, showLabel }: { seconds: number | null, className?: string, showLabel?: boolean }) => {
    className = className || "text-xl";
    const days = Math.floor((seconds || 0) / 86400);
    const hours = Math.floor((seconds || 0) / 3600);
    const minutes = Math.floor(((seconds || 0) % 3600) / 60);
    const secondsLeft = (seconds || 0) % 60;
    return (
        <Tooltip>
            <TooltipTrigger>
                <motion.div
                    className={`flex flex-row gap-2 sm:flex-col sm:items-start md:items-center md:flex-row items-center  md:items-center h-12 gap-x-2 w-full justify-center ${className}`}
                >
                    {showLabel && <span>Time: </span>}
                    {seconds &&
                        <motion.div
                            className={`flex items-center h-12 flex-row px-0
                                gap-x-[2px] 
                                w-full justify-center ${className}`}
                        >
                            {days > 0 && <TimeUntilUnit unit={days} label={"days"} />}
                            {(days > 0 || hours > 0) && <TimeUntilUnit unit={hours % 24} label={"hours"} />}
                            {(days > 0 || hours > 0 || minutes > 0) && <TimeUntilUnit unit={minutes} label={"minutes"} />}
                            <TimeUntilUnit unit={secondsLeft} label={"seconds"} />
                        </motion.div>
                    }
                </motion.div>
            </TooltipTrigger>
            <TooltipContent side={"bottom"} className="max-w-80">
                <h4>
                    How is this calculated?
                </h4>
                <p>
                    A block is mined every 10 minutes on average, but the actual time between blocks can vary.
                </p>
                <p>
                    This means that the exact end time of the auction is not known in advance.
                </p>
                <p>
                    We currently use a very simple approximation of (blocks remaining * 10 minutes) which is why the time jumps back up when you refresh the page.
                </p>
            </TooltipContent>
        </Tooltip>

    )
}

const TimeUntilUnit = ({ unit, label }: { unit: number, label: string }) => (
    <div className="border px-1 bg-secondary/80 rounded-sm" >
        <motion.div
            key={`${label}-${unit}`}
            initial={{ y: -4, opacity: 0.5 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 4, opacity: 1 }}
        >
            {unit.toString().padStart(2, '0')}
        </motion.div>
    </div>
)
