import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useBlockHeightQuery, useCurrentAuctionQuery } from '@/queries/auction';
import { useAccountStore } from '@/stores/accountStore';
import { motion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';

const BlockDigitUnit = ({ digit, className }: { digit: number, className?: string }) => (
    <div className={`${className} border px-1 bg-secondary/90 rounded-sm`}>
        <motion.div
            className='p-0 m-0'
            key={`block-digit-${digit}`}
            initial={{ y: -4, opacity: 0.5 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 4, opacity: 1 }}
            transition={{ duration: 0.2 }}
        >
            {digit}
        </motion.div>
    </div>
)

export const BlockHeightCountdown = ({ className }: { className?: string }) => {
    const network = useAccountStore(state => state.network);
    const { data: blockHeight, error } = useBlockHeightQuery(network);
    const { data: currentAuction } = useCurrentAuctionQuery();
    const [isFlashing, setIsFlashing] = useState(true);

    useEffect(() => {
        if (blockHeight) {
            setIsFlashing(true);
            const timer = setTimeout(() => setIsFlashing(false), 1000);
            return () => clearTimeout(timer);
        }
    }, [blockHeight]);

    const remaining = (currentAuction && blockHeight) ? currentAuction.end_block - blockHeight : null;
    const isNearEnd = remaining ? (remaining <= 5) : false;
    const digits = remaining ? remaining.toString().split('').map(Number) : [];

    return (
        <Fragment>
            {error ? (
                <BlockHeightErrorComponent {...error} />
            ) : (
                <motion.div
                    className={`${className}
                                flex items-r flex-row  w-full justify-center text-lg
                                ${isNearEnd ? 'text-destructive animate' : ''}
                                `}
                >
                    <Tooltip>
                        <TooltipTrigger className={`flex flex-row gap-2 sm:gap-3 sm:flex-col md:flex-row ${className} items-center`}>
                            <motion.span layout >
                                Blocks:
                            </motion.span>
                            <motion.div className='flex'>
                                {digits.map((digit, index) => (
                                    <BlockDigitUnit
                                        className={`flex-col flex justify-center h-6 md:h-5 lg:h-5 xl:h-6 ${className}
                                    ${isNearEnd ? 'text-destructive ' : ''}
                                    ${isFlashing ? 'animate-strobe' : ''}
                                    `}
                                        key={index}
                                        digit={digit}
                                    />
                                ))}
                            </motion.div>
                        </TooltipTrigger>
                        <TooltipContent side="bottom" className="max-w-80">
                            <div className="flex gap-x-2 text-center">
                                <div>
                                    Start Block: <div>
                                        {currentAuction && currentAuction.start_block.toLocaleString()}
                                    </div>
                                </div>
                                <div>
                                    Current Block:
                                    <div>
                                        {blockHeight && blockHeight.toLocaleString()}
                                    </div>
                                </div>
                                <div>
                                    End Block:
                                    <div>
                                        {currentAuction && currentAuction.end_block.toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </motion.div>
            )}
        </Fragment>
    );
};

export const BlockHeightErrorComponent = (error: Error) => (
    <div
        className='flex flex-row gap-x-2 items-center text-destructive'
    >Progress could not be loaded, error loading block height.
        {error.message && <span className='text-xs'>{error.message}</span>}
    </div>
);